<!--  -->
<template>
  <div id="container">
   
  </div>
</template>

<script>
import * as Three from 'three'
// 导入轨道控制器
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// 引入模型加载
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// 动画库
import gsap from 'gsap'
// import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader'
let scene = null,
camera=null,
renderer=null,
mesh=null,
animationMixer=null,
clock=null,
loader=null,
// 第一个动画
action=null,
// 第二个动画
actions=null,
amtion=null,
amtions=null,
// 判断位置是否一致
intersect=null
// 初始的坐标
// threelistZ=0,
// threelistX=-10
// glbe=null
export default {
  data () {
    return {
        // 结束时的坐标
        // stopX:'',
        // stopZ:'',
        // stoptime:'',
        // amtion:'',
        // time:''
    };
  },
  methods:{
    init(){
      let container = document.getElementById('container');

      // 添加相机
      camera = new Three.PerspectiveCamera(70, container.clientWidth/container.clientHeight, 0.01, 1000);
      // 相机的位置
      var vector = new Three.Vector3( 0, 0, - 1 );
      vector.applyQuaternion( camera.quaternion );
      // angle = vector.angleTo( target.position )
      console.log(vector)
     
      camera.position.z = -260
      camera.position.y=0
      camera.position.x=0
      //相机与模型最小距离，模型看上去大
      // camera.minDistance = 80;
      //       //相机与模型最大距离，模型看上去小
      //       camera.maxDistance = 1000;
      // 场景
      scene = new Three.Scene()
      // 创建几何体 x y z
      let geometry = new Three.BoxGeometry(300,100,360);
      // 材质
      // let a=new Three.TextureLoader().load(``)
        
      // let material = new Three.MeshBasicMaterial({color:0x00ff00});
      // 设置材质贴图纹理 左右，上下，前后
      let ar=['3','1','6','5','4','2']
      // 材质添加
      let arrboxmesh=[]
      ar.forEach((item)=>{
        // 纹理加载
        let texture=new Three.TextureLoader().load(`static/image/image/${item}.jpg`)
        // if(item==='3'||item==='5'){
        //   texture.rotation=Math.PI
        //   arrboxmesh.push(new Three.MeshBasicMaterial({map:texture}))
        // }else {
          arrboxmesh.push(new Three.MeshBasicMaterial({map:texture}))
        // }
        // console.log(`../image/${item}.jpg`)
      })
      // 将几何体材质合成物体
      console.log(arrboxmesh)
      
      mesh = new Three.Mesh(geometry, arrboxmesh);
      mesh.geometry.scale(1,1,-1)
      mesh.scale.set(2,2,2);    //设置大小为原来的1.5倍
      mesh.traverse(function (child) {
        if (child.isMesh) {
            child.frustumCulled = false;
        }
    });
      scene.add(mesh);
      //遍历子物体，如果是mesh就让他不裁剪消失
     
      
      // 初始化渲染器
      renderer = new Three.WebGLRenderer({antialias:true});
      // 渲染器的大小
      renderer.setSize(container.clientWidth,container.clientHeight);
      // 设置背景透明
      // 设置透明
      renderer.setClearAlpha(0.1)
      // 将渲染器添加到页面
      container.appendChild(renderer.domElement);

      // 加载模型
      // let that=this
     
      // 添加3d
       loader = new GLTFLoader();
    loader.load( '/static/glb/1111.glb', function ( glb ) {
      // glbe=glb.scence
      // console.log(glb.scene);
      glb.scene.scale.set(30,30,30)
      glb.scene.position.y = -100;
      // glb.scene.position.x = 0;
      glb.scene.position.z = -10;
      glb.scene.rotation.y=Math.PI
      glb.scene.name= 'train'
    console.log(glb)
      glb.scene.traverse( function ( child ) {
        if ( child.isMesh ) {
            child.frustumCulled = false;
            //模型阴影
            child.castShadow = true;
            //模型自发光
            child.material.emissive =  child.material.color;
            child.material.emissiveMap = child.material.map ;
        }})
        // let mixer = new Three.AnimationMixer(glb.scene);
        // console.log(mixer)
        animationMixer = new Three.AnimationMixer(glb.scene);
        clock = new Three.Clock();

        // 获取位置
        // 获取坐标
      var raycaster = new Three.Raycaster();//光线投射，用于确定鼠标点击位置
    var mouse = new Three.Vector3();//创建二维平面
    window.addEventListener("mouseup",mouseup);//页面绑定鼠标点击事件
    // //点击方法
    window.addEventListener("mousedown",mousedown);
    function mousedown(e){
      console.log(e)
      
      //将html坐标系转化为webgl坐标系，并确定鼠标点击位置
      mouse.x =  e.clientX / renderer.domElement.clientWidth*2-1;
      mouse.y =  -(e.clientY / renderer.domElement.clientHeight*2)+1;
      //以camera为z坐标，确定所点击物体的3D空间位置
      raycaster.setFromCamera(mouse,camera);
      //确定所点击位置上的物体数量
       intersect = raycaster.intersectObjects(scene.children);
      // console.log(intersect[0])
    }
    function mouseup(e){
      console.log(e)
      
        //将html坐标系转化为webgl坐标系，并确定鼠标点击位置
        mouse.x =  e.clientX / renderer.domElement.clientWidth*2-1;
        mouse.y =  -(e.clientY / renderer.domElement.clientHeight*2)+1;
        //以camera为z坐标，确定所点击物体的3D空间位置
        raycaster.setFromCamera(mouse,camera);
        //确定所点击位置上的物体数量
        var intersects = raycaster.intersectObjects(scene.children);
        //选中后进行的操作
        if(intersects[0].screenX!=intersect[0].screenX){
          console.log('1')
        }else {
          if(intersects.length){
          // console.log('本地坐标',mesh.position);
          console.log(intersects[0])
         
             var selected = intersects[0];//取第一个物体
            console.log("x坐标:"+selected.point.x);
            console.log("y坐标:"+selected.point.y);
            console.log("z坐标:"+selected.point.z);
            let x=0-selected.point.x
            let z=0-selected.point.z
            var time=''
            // if(selected.point.x<295&&selected.point.x>-295&&selected.point.z<360&&selected.point.z>-360&&selected.point.y<-90){
            //   threelistX=x
            // threelistZ=z
            // }
            
            console.log('模型',glb.scene.position.z,glb.scene.position.x)
            console.log(z,x,selected.point.z,selected.point.x)
            if(amtion!=null){
              amtion.pause()
              amtions.pause()
              // console.log('结束上个动画')
            }
            if(x>1){
              if(z>1){
                time=(z/10+x/10)/2
              }else {
                time=(z/-10+x/10)/2
              }
              // console.log(time,'1')
            }else {
              // console.log(x,z)
              if(z>1){
                time=(z/10+x/-10)/2
                // console.log(time,z)
              }else {
                time=(z/-10+x/-10)/2
                // console.log(z,time)
              }
              // time=x*-1/10
              
            }
            console.log(x,z)
            // this.stopX=x
            // this.stopZ=z
            // this.stoptime=time
            
            // console.log(this.stopX,this.stopZ,time)
            // if(selected.point.y<-140){console.log('2')}
            // let w=x*10
            if(selected.point.x<295&&selected.point.x>-295&&selected.point.z<360&&selected.point.z>-360&&selected.point.y<-90){
              // console.log('1')
              // 面向后面
              if(selected.point.z>glb.scene.position.z&&selected.point.x==glb.scene.position.x){
                // console.log('w')
                glb.scene.rotation.y= Math.PI/2
              }else if(selected.point.z<glb.scene.position.z&&selected.point.x==glb.scene.position.x) {
                // console.log('s')
                glb.scene.rotation.y= Math.PI
              }else if (selected.point.z==glb.scene.position.z&&selected.point.x>glb.scene.position.x){
                // console.log('a')
                glb.scene.rotation.y= Math.PI/2
              }else if(selected.point.z==glb.scene.position.z&&selected.point.x<glb.scene.position.x){
                // console.log('d')
                glb.scene.rotation.y= Math.PI*3.5
              }
              else if (selected.point.z>glb.scene.position.z&&selected.point.x>glb.scene.position.x){
                // console.log('wa')
                glb.scene.rotation.y= Math.PI/3
              }else if (selected.point.z>glb.scene.position.z&&selected.point.x<glb.scene.position.x){
                // console.log('wd')
                glb.scene.rotation.y= Math.PI*3.7
              }
              else if(selected.point.z<glb.scene.position.z&&selected.point.x<glb.scene.position.x){
                // console.log('sd')
                glb.scene.rotation.y= Math.PI*3.3
              }
              else if(selected.point.z<glb.scene.position.z&&selected.point.x>glb.scene.position.x){
                // console.log('sa')
                glb.scene.rotation.y= Math.PI*2.9
              }
              const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
               action =  animationMixer.clipAction(animationClip);
               const animationClips = glb.animations.find(animationClip => animationClip.name ==="stop");
                          actions =  animationMixer.clipAction(animationClips);
               amtion =gsap.to(glb.scene.position, {
                        // 方向
                        x: -x,
                        z:-z,
                        // 时间
                        duration: time,
                        // 循环的次数
                        repeat: 0,
                        // 设置往返
                        // yoyo: true,
                        // 设置延迟
                        // delay: 2,
                        // 动画开始
                        onStart() {
                          console.log('动画开始')
                         
                          action.play();
                        },
                        
                        // 动画结束
                        onComplete() {
                          console.log('动画结束')
                          // let that=this
                          action.stop();
                         
                          actions.play()
                          // this.wenapplist()
    // action.stop();
                        }
                })
                // let camX=x-50
                console.log("x坐标:"+selected.point.x);
            console.log("y坐标:"+selected.point.y);
            console.log("z坐标:"+selected.point.z);
            console.log(camera.position)
                // if(x>1){var camX=x+30}else {
                //    camX=x-30
                // }
                // if(z>1){var camZ=z+30}else {
                //    camZ=x-30
                // }
                let camX=0-selected.point.x
            let camZ=260-selected.point.z
                // let camZ=z-50
                amtions =gsap.to(camera.position, {
                        // 方向
                        x: -camX,
                        z:-camZ,
                        // 时间
                        duration: time,
                })
                console.log(amtion)
            }
          // let z=(selected.point.z-mesh.position.z)/2
          // let x=(selected.point.x-mesh.position.x)/2
          // let y=(selected.point.y-mesh.position.y)/2
          // console.log(z)

        }
        }
       
    }

      // 按键事件
    //   const animationClip = glb.animations.find(animationClip => animationClip.name ==="Running");
    // const action =  animationMixer.clipAction(animationClip);
    // action.play();
      document.onkeydown = function (event) {
        // console.log(event)
         var box = new Three.Box3().setFromObject( mesh );
         var glbfetZ=glb.scene.position.z
         var glbfetX=glb.scene.position.x
        //  var glbfetY=glb.scene.position.y
// var size = box.size()
console.log(box,glb.scene.position)
    if(event.key==='w'){
      // this.amtion.pause()
     
      // console.log(vector)
      // console.log()
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
    glb.scene.rotation.y= Math.PI*2
    // if()
    // console.log(box.max.z)
    if(glbfetZ<360){
      
      glb.scene.position.z += 0.3;
      camera.position.z =camera.position.z +0.2
      // console.log(glb.scene.position)w
      // setTimeout(function(){
      //   action.stop()
      // },1000)
    }
      
      // action.stop()
    }else if(event.key==='a'){
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
      glb.scene.rotation.y= Math.PI/2
      
      if(glbfetX<295){
      
        glb.scene.position.x += 0.5;
      camera.position.x =camera.position.x +0.5
    }
    }else if(event.key==='d'){
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
      glb.scene.rotation.y= Math.PI*3.5
     
      if(glbfetX>-295){
      
        glb.scene.position.x -= 0.5;
      camera.position.x =camera.position.x -0.5
  }
    }else if(event.key==='s'){
      const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    const action =  animationMixer.clipAction(animationClip);
    action.play();
      glb.scene.rotation.y= Math.PI
      if(glbfetZ>-365){
        glb.scene.position.z -= 0.5;
      camera.position.z =camera.position.z -0.5
}
    }else {
      console.log('1')
    }
  }
      
    // const animationClip = glb.animations.find(animationClip => animationClip.name ==="walk");
    // const action =  animationMixer.clipAction(animationClip);

    // action.play();
    
    scene.add(glb.scene);
    // console.log(action)
    }, undefined, function ( error ) {
      console.log( error );
    } );

      // 创建轨道控制器 相机围绕模型看到的角度
      const OrbitControl = new OrbitControls(camera, renderer.domElement)
      // 设置轨道自然
      OrbitControl.enableDamping = true
      // 设置惯性
      OrbitControl.update()
      const axhelp = new Three.AxesHelper(300)
scene.add(axhelp)
    // 设置地面平板
    // 
      

    },

    // // 模型移动
    // plane(){
      
    // },
    // 获取坐标
    animate(){
      // 浏览器自动渲染下一帧
      requestAnimationFrame(this.animate);
      // var animationMixer = new Three.AnimationMixer(scene);
      
      // 渲染到页面
      renderer.render(scene,camera); 
      if(animationMixer!==null){
    //clock.getDelta()方法获得两帧的时间间隔
    // 更新混合器相关的时间
    animationMixer.update(clock.getDelta());
  }
      // console.log(amtion)
      // animationMixer.update( clock.getDelta() )
    },
    wenapplist(e){
      console.log(e)
      action.stop();
    }
  },
  
  mounted(){
      this.init()
      this.animate()
      // this.plane()
  }

}

</script>
<style scoped>
#container{
    width: 100vw;
    height: 100vh;
    background-image: url('../../public/static/image/8.jpg');
    -webkit-background-size: cover;
    background-size: cover;
}

</style>
